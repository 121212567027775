.login__div {
    display: flex;
    justify-content: center;
    margin-top: 15vh;
}

.login_paper {
    width: 280vw;
}

.login__card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;
    padding: 12vh 0;
}

.login__typography__h5 {
    margin-bottom: 1rem !important;
}

.login__input__email{
    margin-bottom: 1em;
}